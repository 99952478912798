body {
    margin: 0;
    font-family: "Ubuntu", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

input {
    font-family: "Ubuntu";
}

.btn,
button {
    transition: background-color 200ms;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    border: 0;
    font-size: 20px;
    padding: 10px 15px;
    border-radius: 4px;
    background-color: #EC008C;
    color: #fff;
}

.btn:active,
button:active,
.btn:hover,
button:hover {
    background-color: #b6006c;
    text-decoration: none;
}

.page-not-found {
    margin: 50px;
    text-align: center;
}

.page-not-found h1 {
    color: #EC008C;
    margin: 0 0 50px 0;
}

.page-not-found h2 {
    color: #EC008C;
}

.app {
    position: relative;
    min-height: 100vh;
}

.site {
    padding-bottom: 110px;
}

header {
    font-family: "Audiowide";
    padding-top: 10px;
}

header .svg {
    display: inline-block;
    width: 50px;
}

header .logo {
    margin: 0 50px;
    text-align: center;
}

header h1 {
    font-size: 24px;
    display: inline;
    margin: 0 0 0 10px;
}

header a:hover,
header a:focus {
    color: #EC008C;
}

header .search {
    padding: 0 50px;
}

header .search {
    margin: 15px 0;
}

header .search input[type=text] {
    font-size: 20px;
}

header .search .search-field {
    max-width: 600px;
}

header .mode {
    position: absolute;
    top: 15px;
    right: 30px;
}

@media (min-width: 640px) {
    header {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0;
    }

    header .search {
        flex-grow: 1;
        padding: 0 50px 0 0;
    }

    header .mode {
        position: unset;
        top: unset;
        right: unset;
        padding: 0 20px;
    }
}

.home {
    margin: 20px auto;
    text-align: center;
    height: 300px;
    max-width: 800px;
    font-weight: bold;
}

footer {
    font-size: 14px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 70px;
    margin-top: 40px;
    background-color: #1D1E25;
    border-top: 2px solid #EC008C;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

footer .copyright {
    text-align: center;
}

footer .logo {
    margin: 10px auto;
}

footer .logo .ls-logo {
    width: 50px;
}

footer .credit {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
}

footer ul li {
    margin-bottom: 5px;
}

.error {
    text-align: center;
    font-size: 20px;
}

.error span {
    display: inline-block;
    padding: 5px 10px;
    min-width: 200px;
    margin: 20px 0;
    color: #FF47B6;
}

.progress {
    padding: 20px;
    margin: 0;
    font-size: 20px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 10px;
    width: 80%;
    max-width: 400px;
    transform: translate(-50%, -50%);
    box-shadow: 0 8px 8px rgb(0 0 0 / 20%);
}

.progress .message {
    margin-bottom: 10px;
}

.progress .song {
    color: #EC008C;
}

.progress .pb {
    max-width: 600px;
    margin: 20px auto 0 auto;
}

.search-results ul {
    width: 320px;
    list-style: none;
    margin: 70px auto 0 auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.search-results ul li {
    text-align: center;
    max-width: calc(100%);
    flex-basis: calc(100%);
    margin-bottom: 60px;
    border-radius: 10px;
    padding: 20px 10px;
    transition: background-color 200ms;
}

.search-results ul li:hover {
    cursor: pointer;
}

.search-results ul li a {
    text-decoration: none;
}

.search-results ul li a:hover {
    text-decoration: none;
}

.search-results ul .result {
    display: flex;
    flex-direction: column;
    position: relative;
}

.search-results ul .cover {
    margin: 0 auto 20px auto;
}

.search-results ul .cover img {
    width: 100%;
    display: block;
    border-radius: 10px;
    box-shadow: 0 4px 8px -8px rgb(0 0 0 / 60%);
}

.search-results ul li:hover .cover img {
    box-shadow: 0 8px 12px rgb(0 0 0 / 80%);
}

.search-results ul .analyze {
    opacity: 0;
    text-align: center;
    position: absolute;
    top: 0;
    margin-top: 100%;
    left: 50%;
    transform: translate(-50%, -260%);
    transition: opacity .2s;
}

.search-results ul .analyze .btn {
    border: 1px solid #fff;
    position: relative;
    overflow: hidden;
}

.search-results ul .analyze .btn span {
    position: relative;
    display: block;
    left: 0;
    transition: all .2s ease-out;
}

.search-results ul .analyze .btn .ls-logo {
    left: -200px;
    position: absolute;
    width: 50px;
    transition: all .2s ease-out;
}

.search-results ul .analyze .btn:hover {
    background-color: #282c34;
}

.search-results ul .analyze .btn:hover .ls-logo {
    left: 23px;
}

.search-results ul .analyze .btn:hover span {
    left: 150px;
}

.search-results ul li:hover .analyze {
    opacity: 0.80;
    transition: opacity 200ms;
}

.search-results ul li:hover .analyze:hover {
    opacity: 1;
}

.search-results ul .details {
    flex-grow: 1;
}

.search-results ul .album {
    margin: 0 0 10px 0;
    color: #EC008C;
    font-weight: bold;
}

.search-results ul .artist {
    font-style: italic;
    margin: 0 0 10px 0;
}

.search-results ul .none {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
    margin: 0;
    padding: 0 20px;
    font-size: 20px;
}

.search-results ul .none:hover {
    box-shadow: none;
    border: none;
    cursor: default;
}

.search-results ul .btn {
    position: relative;
    top: -20px;
}

@media (min-width: 640px) {
    .search-results ul {
        width: 640px;
    }

    .search-results ul li {
        max-width: calc(50%);
        flex-basis: calc(50% - 22px);
    }

    .search-results ul .result {
        padding: 0 10px;
    }

    .search-results ul .cover {
        width: 100%;
    }
}

@media (min-width: 960px) {
    .search-results ul {
        width: 960px;
    }

    .search-results ul li {
        max-width: calc(25%);
        flex-basis: calc(25% - 20px);
    }
}

@media (min-width: 1280px) {
    .search-results ul {
        width: 1280px;
    }

    .search-results ul li {
        max-width: calc(20%);
        flex-basis: calc(20% - 22px);
    }
}

@media (min-width: 1280px) {
    .search-results ul {
        width: calc(100% - 50px);
    }
}

@media (min-width: 1900px) {
    .search-results {
        font-size: 1.2em;
    }

    .search-results ul {
        width: calc(100% - 50px);
    }
}

.pagination {
    text-align: center;
}

.pagination .btn {
    display: inline-block;
    padding: 0;
    width: 178px;
    line-height: 47px;
    font-size: 20px;
    margin: 0 20px;
}

.pagination .btn:last-child {
    margin-top: 20px;
}

@media (min-width: 450px) {
    .pagination .btn:last-child {
        margin-top: 0;
    }
}

.analyze {
    text-align: center;
}

.analyze h2 {
    font-weight: bold;
    font-size: 20px;
    margin: 0 0 10px 0;
    color: #EC008C;
}

.analyze h3 {
    font-size: 16px;
    margin: 20px 0 0 0;
    color: #ec008c;
}

.analyze h4 {
    color: #ec008c;
}

.analyze .artist {
    font-style: italic;
    margin: 0 0 10px 0;
}

.analyze ul li {
    margin-bottom: 5px;
}

.analyze .tracks-wait {
    margin-top: 20px;
}

.analyze .album {
    margin: 40px auto 0 auto;
}

.analyze .cover .art {
    width: 300px;
    margin: 0 auto;
}

.analyze .cover img {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgb(0 0 0 / 60%);
}

.analyze .cover ul.tags {
    text-align: center;
    margin: 20px auto;
}

.analyze .cover ul.tags li {
    display: inline-block;
    margin: 0 10px 10px 0;
}

.analyze .cover ul.tags li a {
    display: block;
    border: 1px solid #ec008c;
    border-radius: 4px;
    color: #ec008c;
    padding: 5px;
    font-size: 12px;
    text-decoration: none;
}

.analyze .cover ul.tags li a:hover {
    background-color: #ec008c;
    color: #fff;
}

.analyze .details {
    margin-top: 20px;
}

.analyze .details h3 {
    margin: 20px 0 10px 0;
}

.analyze .info {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    padding: 0 20px;
    font-size: 14px;
}

.analyze .misc-stats h3 {
    margin-bottom: 20px;
}

.analyze .misc-stats table {
    width: 100%;
    max-width: 90%;
    margin: 0 auto 40px auto;
    border-collapse: separate;
    border-spacing: 0 15px;
}

.analyze .misc-stats table td.caption {
    width: 120px;
    font-weight: bold;
    vertical-align: top;
    text-align: right;
}

.analyze .misc-stats table td.words {
    vertical-align: top;
    text-align: left;
    padding-left: 20px;
}

@media (min-width: 640px) {
    .analyze .album {
        display: table;
        margin: 40px auto 0 auto;
    }

    .analyze .cover {
        display: table-cell;
        width: 50%;
        padding: 0 20px;
        justify-content: right;
        text-align: right;
        vertical-align: top;
    }

    .analyze .cover ul.tags {
        width: 300px;
        text-align: left;
    }

    .analyze .details {
        display: table-cell;
        width: 50%;
        padding: 0 20px;
        justify-content: left;
        vertical-align: top;
        text-align: left;
        margin-top: 0;
    }
}

.stats .divider-bar {
    height: 10px;
    margin: 20px 0;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px;
}

.stats .song-divider {
    height: 1px;
    margin: 20px auto;
    max-width: 80%;
}

.stats .chart {
    height: 350px;
    margin: 0 auto;
    border: 0px solid #ddd;
    border-radius: 10px;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header::before,
.header::after {
    content: '';
    display: block;
    height: 0.09em;
    min-width: 20vw;
}

@media (min-width: 960px) {

    .header::before,
    .header::after {
        min-width: 30vw;
    }
}